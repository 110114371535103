@import '../../../theme/styles/mixins';

.container {
  width: 480px;
  max-width: 480px;
  max-height: calc(var(--vh100) - 100px);

  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    width: 100%;
    max-width: 100%;
  }

  > svg {
    width: 54px;
    height: 54px;
    fill: var(--colors-text-danger);
    margin-bottom: 12px;
  }

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: var(--font-heading-weight);
    margin-bottom: 8px;
    word-break: break-word;
  }

  .message {
    text-align: center;
    word-break: break-word;
  }

  .footer {
    margin-top: 24px;
    width: 100%;
    display: flex;
  }
}
