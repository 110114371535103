@import '../../../../theme//styles/mixins';

.navContainer {
  background-color: var(--colors-components-navBar-background);
  height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);

  // sticky styling
  position: fixed;
  width: 100%;
  z-index: 3;

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .navLeftContainer,
  .navRightContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
  }

  .navLeftContainer {
    flex: 1;
  }

  .navBrand {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-right: 24px;

    img {
      width: 176px;
      object-fit: contain;

      @include mobile {
        width: 130px;
      }
    }
  }

  .navActions {
    display: flex;
    flex-direction: row;
  }

  .actionButtonWrapper {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
    gap: 16px;

    @include mobile {
      margin-right: 5px;
    }
  }

  .navToggle {
    height: 36px;
    width: 36px;
    padding: 10px;
    margin-right: 10px;
    background-color: var(
      --colors-components-navBar-button-background-0
    ) !important;

    &::after {
      background-color: var(
        --colors-components-navBar-button-background-0
      ) !important;
    }

    display: none;

    @include mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      height: 16px;
      width: 16px;
      fill: var(--colors-components-navBar-button-content-default-0);
    }
  }

  &.whiteLabeled {
    .navLeftContainer {
      flex: 0;
    }

    .navBrand {
      margin: 0 24px;
    }

    .navMiddleContainer {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      z-index: 1;
    }
  }
}
