@import '../../theme/styles/mixins';

.container {
  padding: 20px 0px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.preOrderInfoButton {
  display: none;
  height: 36px;
  padding: 7px 10px;

  @include tablet {
    display: flex;
  }

  svg {
    height: 18px;
    width: 18px;
  }

  span {
    margin-left: 6px;
    font-weight: 500;

    @include smallMobile {
      display: none;
    }
  }
}
