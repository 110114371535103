.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;

  & > div {
    display: flex;
  }
}

.dropdownButton {
  flex: 1;
  display: flex;
  justify-content: space-between;
  background: none;
  border-radius: 6px;
  border: 1px solid var(--colors-borders-secondary);
  height: 44px;

  svg {
    fill: unset;
  }
}

.dropdownPopup {
  width: 100%;
}

.label {
  margin-bottom: 6px;
  font-weight: var(--font-heading-weight);
  font-size: 1.25rem !important;
}

.spacer {
  margin-bottom: 18px;
}

.placeholder {
  opacity: 0.4;
}
