@import '../../theme/styles/mixins';

.gridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  button {
    height: 70px;
    width: 100%;
    padding: 8px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background-color: var(--colors-surfaces-primary-2);

    svg {
      height: 24px;
      width: 24px;
    }
  }

  button:hover {
    background-color: var(--colors-highlights-1);
  }

  @include mobile {
    grid-template-columns: repeat(2, 1fr);
  }
}

.selected {
  background-color: var(--colors-highlights-1);
  border: 3px solid var(--colors-highlights-0) !important;
}
