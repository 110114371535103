.separator {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin: 24px 0;

  .text {
    margin: 0 8px;
    color: var(--colors-text-body-1);
  }
}

.line {
  flex: 1;
  height: 0.5px;
  background-color: var(--colors-text-body-2);
}
