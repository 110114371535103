@import '../../../../theme/styles/mixins';

.profileIcon {
  > svg {
    width: 18px;
    height: 18px;
    margin-right: 8px;

    @include tablet {
      margin-right: 0;
    }
  }
}

.profileContent {
  display: flex;
  flex-direction: column;
  gap: 6px;
  text-align: left;

  @include tablet {
    display: none;
  }

  &.outletDetailsPage {
    @media screen and (min-width: 990px) and (max-width: 1368px) {
      display: none;
    }
  }
}

.profileName {
  display: block;
  line-height: 1;
  max-width: 120px;
  word-break: break-all;
  @include textEllipsis(1);
}

.profileUserName {
  color: var(--colors-text-body-2);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 400 !important;
  line-height: 1;
  max-width: 120px;
  word-break: break-all;
  @include textEllipsis(1);
}

.profileDropdownPopup {
  margin-top: 1px;
  width: 150px;

  &.guestCustomer {
    width: 190px;
  }
}
