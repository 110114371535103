@import '../../../theme/styles/mixins';

.container {
  padding: 0px 10px;
}

.header {
  padding: 0px 10px 10px;
  border-bottom: 0.5px solid var(--colors-text-body-2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.headerTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  > div {
    margin-left: 10px;
  }
}

.headerActions {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.headerCloseButton {
  height: 36px;
  width: 36px;
  padding: 5px;

  @include xxDesktop {
    display: none;
  }
}

.orderFormat {
  padding-top: 8px;

  svg {
    height: 12px;
    width: 14px;
    margin-right: 4px;
    fill: var(--colors-text-body-1);
  }
}

.orderFormatText {
  color: var(--colors-text-body-4);
  font-weight: var(--font-heading-weight);
}
