@import '../../theme/styles/mixins';

.container {
  max-width: 562px;
  overflow: auto;
  margin-left: -20px;
  margin-right: -20px;

  p.description {
    padding: 0 20px;
  }

  @include mobile {
    max-width: unset;
  }
  @include smallMobile {
    max-height: calc(var(--vh100) - 150px);
  }
}

.accountSignUpOption {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  padding: 0 20px;

  @include mobile {
    flex-direction: column;
    align-items: center;
  }

  .textRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    svg {
      height: 24px;
      width: 24px;
      fill: var(--colors-body-text-0);
    }

    p {
      margin-left: 8px;
    }
  }

  > button {
    margin-left: 16px;
    padding: 13px 16px;
    max-height: 42px;
    width: 50%;

    @include mobile {
      margin-left: 0px;
      width: 100%;
    }
  }
}
