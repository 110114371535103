@import '../../theme/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 100%;

  input {
    height: 44px;
    font-size: 1.4rem;
    border-radius: 6px;
    border-style: solid;

    @include mobile {
      @include safari {
        font-size: 1.6rem;
      }
    }
  }
}

.valid {
  input {
    border-color: var(--colors-borders-secondary) !important;
  }
}

.label {
  margin-bottom: 6px;
  font-weight: var(--font-heading-weight) !important;
  font-size: 1.25rem !important;

  span {
    line-height: normal;
  }
}

.spacer {
  margin-bottom: 18px;
}
