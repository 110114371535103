@import '../../../theme/styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  padding: 12px 0px;
}

.selectedItemContainer {
  display: flex;
  flex-direction: row;
  margin: 0 -20px;
  padding: 12px 20px;
  background-color: var(--colors-surfaces-primary-4);
}

.itemQuantity {
  flex: 1;
  margin-right: 16px;
}

.quantityText {
  margin-right: 0px;
  max-width: 35px;
}

.quantityButton {
  background-color: var(--colors-surfaces-primary-1);
  color: var(--colors-text-body-0);
  font-size: 14px;
  padding: 3px 8px 3px 14px;

  svg {
    color: var(--colors-text-body-2);
    height: 32px;
    width: 32px;
    padding-left: 5px;
  }
}

.quantityButton:focus {
  background-color: var(--colors-surfaces-primary-1);
}

.itemDetailsContainer {
  flex: 6;
}

.noModifications {
  display: flex;
  align-items: center;
}

.itemCost {
  flex: 2;
  text-align: right;
}

.discountPrice {
  text-decoration: line-through;
}

.separator {
  background-color: var(--colors-surfaces-primary-1);
  border: none;
  height: 0.5px;
}

.ticketStatus {
  margin-left: 30px;
  width: 60px;
  background-color: var(--colors-text-success);
  text-align: center;
  padding: 2px 5px;
  border-radius: 4px;
  margin-top: 16px;
}

.pickedUpStatus {
  margin-left: 30px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    height: 14px;
    width: 14px;
    margin-left: 8px;
    padding: 3px;
    fill: var(--colors-text-body-3);
    background-color: var(--colors-text-body-4);
    border-radius: 50%;
  }
}

.hasItemStatus {
  padding-top: 4px;
}
