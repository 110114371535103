@import '../../../theme/styles/mixins';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 16px;

  @include mobile {
    flex-direction: column;
    gap: 0;
  }
}

.column {
  flex-basis: 50%;
  max-width: 50%;

  @include mobile {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.fieldSetTitle {
  font-weight: var(--font-heading-weight);
  margin-bottom: 16px;
  font-size: 14px;
}

.flexContainer {
  flex: 1;
}
