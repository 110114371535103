.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.rightContent {
  display: flex;
  flex-direction: row;
}

.separator {
  margin-top: 10px;
  padding: 16px 0;
  border-top: 1px solid var(--colors-text-body-2);
}

.bold {
  font-weight: var(--font-heading-weight);
}

.totalItemsCount {
  background-color: var(--colors-text-body-1);
  height: 28px;
  width: 28px;
  margin-right: 8px;
  border-radius: 50px;
  text-align: center;

  p {
    line-height: 28px;
  }
}
