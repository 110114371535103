@import '../../../../theme/styles/mixins';

.button,
.button:focus {
  padding: 10px 15px;
  border-radius: 50px;
  display: flex;
  justify-content: flex-start;
  border: none;
  align-items: center;
  cursor: pointer;
  height: 36px;
  height: 100%;
  flex-grow: 1;

  background-color: var(--colors-components-navBar-button-background-0);

  @include tablet {
    background-color: var(--colors-button-secondary-background-default);
  }

  &:hover,
  &::after {
    background-color: inherit;
  }

  .serviceTimeData {
    display: flex;
    align-items: center;
    color: var(--colors-components-navBar-button-content-default-0);

    @include tablet {
      color: var(--colors-button-text-secondary-0);
    }

    svg {
      height: 16px;
      width: 16px;
      margin-right: 8px;
      fill: var(--colors-components-navBar-button-content-accent-0);

      @include tablet {
        fill: var(--colors-button-text-primary-0);
      }
    }
  }

  &.selected {
    background-color: var(--colors-components-navBar-button-background-1);

    @include tablet {
      color: var(--colors-button-text-secondary-0);
      background-color: var(--colors-highlights-0);
    }
  }

  &.inNavBar {
    .serviceTimeData {
      color: var(--colors-components-navBar-button-content-default-0);

      svg {
        fill: var(--colors-components-navBar-button-content-accent-0);
      }
    }

    &.selected {
      .serviceTimeData {
        color: var(--colors-components-navBar-button-content-default-1);

        svg {
          fill: var(--colors-components-navBar-button-content-accent-1);
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
