@import '../../../../theme/styles/mixins';

.container,
.container:focus {
  padding: 10px 12px;
  display: flex;
  justify-content: flex-start;
  height: 42px;
  min-width: 200px;
  border: none;
  align-items: center;
  cursor: pointer;
  background-color: var(--colors-components-navBar-button-background-0);
  max-width: 360px;

  &::after,
  &:hover {
    background-color: var(--colors-components-navBar-button-background-0);
  }

  @include tablet {
    margin: 8px 0;
    background-color: var(--colors-button-secondary-background-default);
    max-width: 100%;

    &::after,
    &:hover {
      background-color: var(--colors-button-secondary-background-default);
    }
  }

  svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
    margin-right: 8px;
  }

  span {
    @include textEllipsis(1);
    color: var(--colors-components-navBar-button-content-default-0);
    max-width: 170px;
    word-break: break-all;

    @include tablet {
      max-width: none;
      color: var(--colors-button-text-secondary-0);
    }
  }

  svg:nth-child(2) {
    display: none;
    margin-right: 0px;
  }

  &:not(.navBarSearch) {
    display: none;

    @include tablet {
      display: flex;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 0px;
      max-width: calc(100vw - 30px);
    }
  }

  &.navBarSearch {
    flex: 1;

    svg {
      fill: var(--colors-components-navBar-button-content-accent-0);
    }

    &.outletDetailsPage:not(.hasPreOrderCheckout) {
      @media screen and (min-width: 990px) and (max-width: 1368px) {
        min-width: 42px;

        > span {
          display: none;
        }

        svg:first-child {
          display: none;
        }

        svg:nth-child(2) {
          display: block;
        }
      }

      &.whiteLabeled {
        @media screen and (min-width: 1368px) and (max-width: 1470px) {
          min-width: 42px;

          > span {
            display: none;
          }

          svg:first-child {
            display: none;
          }

          svg:nth-child(2) {
            display: block;
          }
        }
      }
    }

    &.companyDashboard {
      @include mobile {
        height: 40px;
        min-width: 150px;
        max-width: min-content;
      }

      @include smallMobile {
        min-width: 100px;
        max-width: 120px;
      }

      @media only screen and (max-width: 480px) {
        justify-content: center;
        min-width: 45px;
        max-width: 100px;

        span {
          display: none;
        }

        svg {
          margin-right: 0;
        }
      }
    }

    @include tablet {
      &:not(.companyDashboard) {
        display: none;
      }
    }
  }

  .spinner {
    margin-right: 8px;
  }
}
