@import '../../../theme/styles/mixins';

.formBody {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  overflow: auto;
  max-height: calc(var(--vh100) - 410px);
  padding-bottom: 30px;
  gap: 20px;

  > section {
    padding: 0 20px;
  }

  @include smallMobile {
    overflow: unset;
    max-height: none;
    gap: 12px;
  }
}

.formFooter {
  margin-top: 20px;
  position: relative;
  padding: 0 20px;

  button {
    width: 100%;
  }
}

.formFooter::after {
  content: '';
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  height: 30px;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 1)
  );
}
