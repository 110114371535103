@import '../../theme/styles/mixins.scss';

.container {
  border-left: 1px solid var(--colors-surfaces-primary-1);
  box-shadow: 0px -2px 10px rgb(0 0 0 / 10%);
  padding-top: 72px;

  background-color: var(--colors-surfaces-primary-0);
  display: none;
  width: 420px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  max-height: var(--vh100);

  &.isOpen {
    display: block;
  }

  @include xxDesktop {
    &.isGeneralCheckout {
      box-shadow: none;
    }
  }

  @include mobile {
    width: 100%;
  }
}
